import React, { useState, CSSProperties } from 'react';
import axios from 'axios';
import ProductCard from './components/ProductCard';
import SignIn from './components/SignIn';
import Header from './components/Header';

type MyPaymentMetadata = {};
type valuedata = {};
type AuthResult = {
  accessToken: string,
  user: {
    uid: string,
    id: string,
    username: string
  },
};

const headerStyle: CSSProperties = {
  width: "100%",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#fff",
  position: "fixed",
  top: "0",
  zIndex: "99"
};
const popup: CSSProperties = {
  width: '300px',
  height: "250px",
  backgroundColor: "#fff",
  borderRadius: "10px",
  position: "relative",
  zIndex: "999",
  padding: "20px 0"
};
const popup_c: CSSProperties = {
  display: "flex",
  alignItems: "center",
  width: "260px",
  margin: "0 auto",

}
const popup_c_text: CSSProperties = {
  fontSize: "16px",
  width: "70px"
}
const popup_c_input: CSSProperties = {
  fontSize: "14px",
}

export type User = AuthResult['user'];
interface PaymentDTO {
  amount: number,
  user_uid: string,
  created_at: string,
  identifier: string,
  metadata: Object,
  memo: string,
  status: {
    developer_approved: boolean,
    transaction_verified: boolean,
    developer_completed: boolean,
    cancelled: boolean,
    user_cancelled: boolean,
  },
  to_address: string,
  transaction: null | {
    txid: string,
    verified: boolean,
    _link: string,
  },
};

// Make TS accept the existence of our window.__ENV object - defined in index.html:
interface WindowWithEnv extends Window {
  __ENV?: {
    backendURL: string, // REACT_APP_BACKEND_URL environment variable
    sandbox: "true" | "false", // REACT_APP_SANDBOX_SDK environment variable - string, not boolean!
  }
}

const _window: WindowWithEnv = window;
const backendURL = _window.__ENV && _window.__ENV.backendURL;

const axiosClient = axios.create({ baseURL: `${backendURL}`, timeout: 20000, withCredentials: true });
const axiosClient2 = axios.create({
  baseURL: 'https://new.web3a.pro',
  timeout: 20000,
  withCredentials: true,
});
const config = { headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' } };


export default function Shop() {

  const [user, setUser,] = useState<User | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showtankuang, setshowtankuang] = useState<boolean>(false);

  const [popupshow, setpopupshow] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  // const [id, setid] = useState("");
  const [udata, setudata] = useState({
    accessToken:"",
    user:{
      username:"",
      uid:""
    }
  });
  //  var id= ''

  //登录
  const onlogin = async () => {
    console.log("输入框的值：", inputValue);
    console.log(udata)
    try {
      const response = await axiosClient2.post('/api/member/piBind', { mobile: inputValue, code: inputValue2,username: udata.user.username, uid: udata.user.uid,accessToken:udata.accessToken }); // 替换为实际的接口URL
      console.log(response.data); // 打印返回的数据
      if (response.data.code == 0) {
        // setid(response.data.data.id)
        // console.log(setid)
        // console.log(id)
        setpopupshow(false);
      } else {
        console.log('失败11')
        setshowtankuang(true);
      }
    } catch (error) {

      console.error(error);
    }

    //  setpopupshow(false);
  }
 

  // 发送验证码倒计时
  const send = async () => {
    try {
      const response = await axiosClient2.post('/api/member/sendsms', { mobile: inputValue, type: "login" }); // 替换为实际的接口URL
      console.log(response.data); // 打印返回的数据
    } catch (error) {
      console.error(error);
    }

  }
  // 判断是否绑定
  const getPi = async (authResult: AuthResult) => {
    console.log(authResult)
    setudata(authResult)
    try {
      const response = await axiosClient2.post('/api/member/getPi', { username: authResult.user.username, uid: authResult.user.uid,accessToken:authResult.accessToken      }); // 替换为实际的接口URL
      console.log(response.data); // 打印返回的数据
      if(response.data.code!=0){
        setpopupshow(true);
      }else{

      }
    } catch (error) {
      console.error(error);
    }

  }

  const signIn = async () => {
    const scopes = ['username', 'payments'];
    const authResult: AuthResult = await window.Pi.authenticate(scopes, onIncompletePaymentFound);
    signInUser(authResult);
    setUser(authResult.user);
  }

  const signOut = () => {
    setUser(null);
    signOutUser();
  }

  const signInUser = (authResult: AuthResult) => {
    // console.log(id)
    // authResult.user.id = id
    console.log(authResult)
    axiosClient.post('/api/user/signin', { authResult });
    getPi(authResult)
    return setShowModal(false);
  }

  const signOutUser = () => {
    return axiosClient.get('/api/user/signout');
  }

  const onModalClose = () => {
    setShowModal(false);
  }

  const orderProduct = async (memo: string, amount: number, paymentMetadata: MyPaymentMetadata) => {
    if (user === null) {
      return setShowModal(true);
    }
    const paymentData = { amount, memo, metadata: paymentMetadata };
    const callbacks = {
      onReadyForServerApproval,
      onReadyForServerCompletion,
      onCancel,
      onError
    };
    const payment = await window.Pi.createPayment(paymentData, callbacks);
    console.log(payment);
  }

  const onIncompletePaymentFound = (payment: PaymentDTO) => {
    console.log("onIncompletePaymentFound", payment);
    return axiosClient.post('/api/payments/incomplete', { payment });
  }

  const onReadyForServerApproval = async (paymentId: string) => {

    console.log("onReadyForServerApproval", paymentId);
    try {
      const response = await axiosClient.post('/api/payments/approve', { paymentId }, config); // 替换为实际的接口URL
      console.log(response.data); // 打印返回的数据
      const storedParameter = localStorage.getItem("token"); // 从本地存储中获取参数值
      console.log(storedParameter); // 打印参数值

     // axiosClient2.post('/api/member/getOrder', { order_id: response.data.message, accessToken: storedParameter })
    } catch (error) {
      console.error(error);
    }

  }

  const onReadyForServerCompletion = (paymentId: string, txid: string) => {
    console.log("onReadyForServerCompletion", paymentId, txid);
    axiosClient.post('/api/payments/complete', { paymentId, txid }, config);
  }

  const onCancel = (paymentId: string) => {
    console.log("onCancel", paymentId);
    return axiosClient.post('/api/payments/cancelled_payment', { paymentId });
  }

  const onError = (error: Error, payment?: PaymentDTO) => {
    console.log("onError", error);
    if (payment) {
      console.log(payment);
      // handle the error accordingly
    }
  }

  return (
    <>
      <Header user={user} onSignIn={signIn} onSignOut={signOut} />
      {popupshow && (
        <div style={headerStyle}>
          <div style={popup}>
            <div style={{ textAlign: "center", fontSize: "20px", marginBottom: "20px" }}>
              登录
            </div>
            <div style={popup_c}>
              <p style={popup_c_text}>手机号:</p>
              <div style={{ display: "flex", alignItems: "center", width: "180px" }}>
                <input style={{ width: "100%" }} type="text" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
              </div>

            </div>
            <div style={popup_c}>
              <p style={popup_c_text}>验证码:</p>
              <div style={{ display: "flex", alignItems: "center", width: "180px", justifyContent: "space-between" }}>
                <input style={{ width: "110px" }} type="text" value={inputValue2} onChange={(e) => setInputValue2(e.target.value)} />
                <div style={{
                  width: "60px", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "12px", border: "1px solid #f1f1f1",
                  height: "30px", backgroundColor: "#f5f5f5", borderRadius: "5px"
                }} onClick={send} >发送</div>
              </div>

            </div>
            <div style={{
              width: "130px", height: "35px", margin: "20px auto 10px auto", display: "flex", alignItems: "center", justifyContent: "center",
              backgroundColor: "#3256ea", color: "#fff", borderRadius: "50px"
            }} onClick={onlogin}>
              登录
            </div>
          </div>
        </div>
      )}
      <ProductCard
        name="WEB3A PI支付"
        description="WEB3A PI支付"
        price={3}
        pictureURL="https://new.web3a.pro/uploads//images/image/20230423/7d83ebfa137303f2580.jpg"
        pictureCaption=""
        onClickBuy={() => orderProduct("Order Apple Pie", 3, { productId: 'apple_pie_1' })}
      />
   

      {showModal && <SignIn onSignIn={signIn} onModalClose={onModalClose} />}
      {showtankuang && <div style={{
        background: 'white',
        position: 'absolute',
        left: '15vw',
        top: '40%',
        width: '70vw',
        height: '25vh',
        border: '1px solid black',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        zIndex: "999"
      }}>
        <p style={{ fontWeight: 'bold' }}>执行失败</p>
        <div>

          <button onClick={(e) => setshowtankuang(false)} >关闭</button>
        </div>
      </div>}
    </>
  );
}
